<template>
  <div class="auth">
    <div class="auth__img">
      <img :src="require(`@/assets/images/TGHLogoBlue.svg`)" alt="logo" />
    </div>
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="observer" class="auth__content">
      <h2 class="title">Password Reset</h2>
      <p>Set up a new password</p>
      <section class="form">
        <div class="form__row">
          <div class="form__row_col">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              :rules="{ required: true, min: 8, max: 255 }"
              v-slot="{ errors }"
              name="password"
              vid="confirmation"
            >
              <PasswordInput
                placeholder="Enter your new password"
                type="password"
                name="password"
                :validateError="error || errors[0]"
                v-model="form.password"
                :element="form.password"
                @input="form.password = $event"
              ></PasswordInput>
            </ValidationProvider>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              :rules="{ required: true, confirmed: 'confirmation', min: 8, max: 255 }"
              v-slot="{ errors, valid }"
            >
              <PasswordInput
                :class="{ 'form-control--valid': valid }"
                placeholder="Confirm your new password"
                type="password"
                :validateError="errors[0]"
                v-model="form.password_confirmation"
                :element="form.password_confirmation"
                @input="form.password_confirmation = $event"
              ></PasswordInput>
            </ValidationProvider>
          </div>
        </div>
        <BaseButton
          class="button--full-width button--black button--uppercase"
          text="Save"
          :loading="loading"
          :disabled="invalid"
          @click="save"
        ></BaseButton>
      </section>
    </ValidationObserver>
    <div class="auth__footer" style="padding: 15px 40px">
      <p>© Collekt 2022</p>
      <ul>
        <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    PasswordInput,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
        email: "",
      },
      error: "",
      loading: false,
    };
  },
  created() {
    if (this.$route.query?.email) this.form.email = this.$route.query.email;
    else this.$router.push("/login");
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async save() {
      this.loading = true;
      const data = {
        ...this.form,
        forgot_password: 1,
      };
      let result = await this.resetPassword(data);
      if (result?.error) this.error = result.error;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &__content {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 440px;
    margin: 0 auto;
    h2,
    p {
      text-align: center;
    }
    h2 {
      @include type($fs: 3.4rem, $ls: -1.31px);
    }
    p {
      @include type($fs: 16px, $lh: 1.18, $fw: 500);
      margin-top: 12px;
      margin-bottom: 34px;
    }
    .button {
      margin-top: 35px;
    }
  }
  &__img {
    img {
      width: 150px;
      margin: 6vh auto 8.5vh;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 25px;
    @media only screen and (max-width: $md) {
      padding: 10px 0;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      align-items: center;
    }
    p {
      @include type();
      @media only screen and (max-width: $md) {
        margin: 15px auto 0 0;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
        margin: 15px auto 0;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: $md) {
        justify-content: flex-end;
      }
      li {
        a {
          text-decoration: none;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.16;
          padding: 0 15px;
          color: $lightBlack;
          cursor: pointer;
          @media only screen and (max-width: $md) {
            padding: 0 15px 0 0;
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            padding: 0 15px;
          }
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}
</style>
